<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row class="match-height">
        <b-col lg="12">
          <b-overlay
            :show="ShowRound"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <b-icon-controller
                  font-scale="3"
                  animation="cylon"
                />
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
          </b-overlay>
        </b-col>
        <b-col lg="12">
          <b-card no-body>
            <b-card-body>
              <div class="p-1">
                <h3>ประวัติการออกผลรางวัล</h3>
              </div>
            </b-card-body>
            <b-table
              small
              striped
              hover
              responsive
              class="position-relative items-center"
              :per-page="perPage"
              :items="items"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
            >
              <template #cell(index)="data">
                {{ perPage * (currentPage - 1) + (data.index + 1) }}
              </template>
              <!-- <template #cell(LottoSubHead)="data">
                {{ data.item.LottoSubHead ? NameLottoSubHead(data.item.LottoHead,data.item.LottoSubHead) : '-' }}
              </template> -->
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group
                label="Per Page"
                label-cols="8"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                />
              </b-form-group>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                  @input="GetData()"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  // BFormInput,
  // BButton,
  BPagination,
  BTable,
  BFormSelect,
  BOverlay,
  BIconController,
  VBTooltip,
  // BAvatar,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
// import vSelect from 'vue-select'
import 'animate.css'
import {
  required, min, length,
} from '@validations'
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // vSelect,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    // BFormInput,
    // BButton,
    BPagination,
    BTable,
    // ValidationProvider,
    ValidationObserver,
    BOverlay,
    BIconController,
    // BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      min,
      length,
      ShowRound: false,
      show: true,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [10, 15, 20],
      perPage: 10,
      fields: [
        { key: 'index', label: 'ลำดับที่', visible: true },
        {
          key: 'LottoHead',
          label: 'หวย',
          formatter: value => (this.NameLottoHead(value) || '-'),
          visible: true,
        },
        {
          key: 'LottoSubHead',
          label: 'ประเภท',
          visible: true,
        },
        { key: 'RoundData.CloseDateName', label: 'งวดวันที่', visible: true },
        { key: 'U3', label: 'เลขท้าย 3 ตัว(บน)', visible: true },
        { key: 'U2', label: 'เลขท้าย 2 ตัว(บน)', visible: true },
        { key: 'D2', label: 'เลขท้าย 2 ตัว(ล่าง)', visible: true },
        // { key: 'R1', label: 'รางวัลที่ 1', visible: true },
        // { key: 'F3N1', label: 'เลขหน้า 3 ตัว ที่ 1', visible: true },
        // { key: 'F3N2', label: 'เลขหน้า 3 ตัว ที่ 2', visible: true },
        // { key: 'B3N1', label: 'เลขท้าย 3 ตัว ที่ 1', visible: true },
        // { key: 'B3N2', label: 'เลขท้าย 3 ตัว ที่ 2', visible: true },
        {
          key: 'created_at',
          label: 'เวลาออกผล',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
          visible: true,
        },
        // { key: 'addby', label: 'แก้ไขโดย', visible: true },
        // { key: 'approveby', label: 'ยืนยันโดย', visible: true },
        // { key: 'actions', label: 'เครื่องมือ', visible: true },
      ],
      /* eslint-disable global-require */
      items: [],
      UserData: JSON.parse(localStorage.getItem('userData')),
      LottoHead: null,
      LottoSubHead: null,
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoSubList: null,
      RoundData: {},
      R1: null, // รางวัลที่ 1 [THG]
      F3N1: null, // เลขหน้า 3 ตัว ที่1 [THG]
      F3N2: null, // เลขหน้า 3 ตัว ที่2 [THG]
      B3N1: null, // เลขท้าย 3 ตัว ที่1 [THG]
      B3N2: null, // เลขท้าย 3 ตัว ที่2 [THG]
      U3: null, // 3 ตัวบน [THG]
      U2: null, // 2 ตัวบน [THG]
      D2: null, // 2 ตัวล่าง [THG]
      EditID: null,

    }
  },
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
  },
  async created() {
    this.LottoList = await this.GetSublist()
  },
  mounted() {
    this.GetData()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get('member/LottoList')
        // eslint-disable-next-line
        for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
            for (const c in Res[x].Subhead) {
              SubList.push(
                { text: Res[x].Subhead[c].name, value: Res[x].Subhead[c].SubHeadID, Data: Res[x].Subhead[c] },
              )
            }
          }
          List.push(
            {
              text: Res[x].name,
              value: Res[x].HeadID,
              SubList,
              Data: Res[x],
            },
          )
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    GetData() {
      // let index = 0
      const params = {
        page: this.currentPage,
        perpage: this.perPage,
        UserToken: localStorage.getItem('UserToken'),
      }
      this.$http
        .get('member/result/showall', { params })
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.onFiltered(ResData.mes)
            // ResData.mes.Data.forEach(items => {
            //   this.getAdmin(items.addby, index, 'addby')
            //   this.getAdmin(items.approveby, index, 'approveby')
            //   index += 1
            // })
          }
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // console.log(filteredItems)
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.Data
      // console.log(this.items)
      this.totalRows = filteredItems.total
    },
    async getAdmin(addby, index, key) {
      if (addby) {
        const { data: response } = await this.$http
          .get(`member/admin/show/${addby}`)
        this.items[index][key] = response.name
      }
      return '-'
    },
    NameLottoHead(Val) {
      const Index = this.LottoList.findIndex(({ value }) => value === Val)
      return this.LottoList[Index].text
    },
    NameLottoSubHead(Head, Sub) {
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      if (IndexHead >= 0) {
        const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === Sub)
        if (IndexSub >= 0) {
          return this.LottoList[IndexHead].SubList[IndexSub].text
        }
      }
      return '-'
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
